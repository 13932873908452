import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";

const ProductPlanExport = ({
  listQuantitatives: data,
  show,
  onHide,
  classGroups,
  setSelectedClassGroups,
  selectedClassGroup,
  handleExport,
  selectedDay,
}) => {
  if (!data) {
    return "";
  }

  const renderTable = (data) => {
    let quantitative = data;
    // const costPerDays = quantitative.costPerDays || [];
    const sumTotalRegisterByIdTime = (idTime) => {
      let times = quantitative.times;
      if (!Array.isArray(times)) {
        throw new Error("Lỗi");
      }

      return times
        .filter((time) => time.id_time === idTime)
        .reduce((sum, time) => {
          const foodRegisterSum = time.foods?.reduce((foodSum, food) => {
            const groupRegisterSum = food.groups?.reduce(
              (groupSum, group) => groupSum + (group.totalRegister || 0),
              0
            );
            return foodSum + (groupRegisterSum || 0);
          }, 0);

          return sum + (foodRegisterSum || 0);
        }, 0);
    };
    // if (selectedClassGroup) {
    const filteredCostPerDays = quantitative?.costPerDays;
    // ?.filter(
    //   (c) => Number(c.id_group) === Number(selectedClassGroup.id)
    // );

    const updatedTimes = quantitative?.times?.map((time) => ({
      ...time,
      foods: time.foods?.map((f) => ({
        ...f,
        groups: f.groups
          // ?.filter((g) => Number(g.id) === Number(selectedClassGroup.id))
          ?.map((g) => ({
            ...g,
            ingredents: g.ingredents,
          })),
      })),
      // ?.filter((e) => e.groups?.length > 0),
    }));

    quantitative = {
      ...quantitative,
      costPerDays: filteredCostPerDays,
      times: updatedTimes,
    };

    // }

    return (
      <Table
        id={`data-${quantitative.day_name}-${quantitative.id}`}
        className="d-none"
      >
        <thead>
          <tr>
            <th>
              BẢNG ĐỊNH LƯỢNG CHI TIẾT{" "}
              {dateFormatter(new Date(quantitative.menu_date * 1000))}
            </th>

            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th> CA</th>
            <th> TÊN MÓN</th>
            <th>SỐ PHẦN</th>
            <th>THÀNH PHẦN</th>
            <th>QUY CÁCH</th>

            <th>ĐỊNH LƯỢNG (g)</th>
            <th> KHỐI LƯỢNG (Kg)</th>
          </tr>
        </thead>
        <tbody>
          {quantitative?.times?.map((timeItem) => {
            if (timeItem?.foods?.length === 0) {
              return (
                <tr key={timeItem.id}>
                  <td>{timeItem.time_name}</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            }
            let ingredientTotalQuantitative1 = 0;

            return (
              <React.Fragment key={timeItem.id}>
                <tr>
                  <td>{timeItem.time_name}</td>
                  <td>
                    {timeItem.foods?.length > 0
                      ? timeItem.foods[0]?.food_name
                      : ""}
                  </td>
                  <td>
                    <CurrencyFormat
                      value={
                        timeItem?.foods?.length > 0
                          ? timeItem?.foods[0]?.groups?.reduce(
                              (groupSum, group) =>
                                groupSum + (group.totalRegister || 0),
                              0
                            )
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td>
                    {timeItem?.foods?.length > 0
                      ? timeItem?.foods[0]?.groups[0]?.ingredents[0]
                          ?.ingredent_name
                      : "empty"}
                  </td>
                  <td>empty</td>
                  {quantitative?.costPerDays?.map((groupItem) => {
                    if (timeItem.foods?.length > 0) {
                      const mealQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.totalRegister || 0;
                      const amountQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.ingredents[0]?.ing_quantity || 0;
                      const groupQuantitative = Number(
                        Number(amountQuantity).toFixed(4) * mealQuantity
                      ).toFixed(4);
                      ingredientTotalQuantitative1 +=
                        parseFloat(groupQuantitative);
                    } else ingredientTotalQuantitative1 = 0;
                  })}
                  <td>
                    <CurrencyFormat
                      value={
                        parseFloat(
                          ingredientTotalQuantitative1 /
                            (timeItem.foods?.length > 0
                              ? timeItem?.foods[0]?.groups?.reduce(
                                  (groupSum, group) =>
                                    groupSum + (group.totalRegister || 0),
                                  0
                                )
                              : 1)
                        ) || 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={1}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={
                        parseFloat(
                          ingredientTotalQuantitative1 /
                            (timeItem.foods?.length > 0
                              ? timeItem.foods[0]?.groups[0]?.ingredents[0]
                                  ?.volume_cook
                              : 1)
                          // timeItem.foods[0]?.groups[0]?.ingredents[0]
                          //   ?.volume_cook
                        ) || 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={1}
                    />
                  </td>
                </tr>

                {timeItem?.foods?.length > 0 &&
                  timeItem.foods[0]?.groups[0]?.ingredents
                    ?.slice(1, timeItem.foods[0]?.groups[0]?.length)
                    ?.map((ingredientItem) => {
                      let ingredientTotalQuantitative2 = 0;

                      return (
                        <tr key={ingredientItem.id}>
                          <td>empty</td>
                          <td>empty</td>
                          <td>empty</td>
                          <td>{ingredientItem.ingredent_name || "empty"}</td>
                          <td>empty</td>

                          {quantitative?.costPerDays?.map((groupItem) => {
                            const mealQuantity =
                              timeItem.foods[0]?.groups?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )?.totalRegister || 0;

                            const amountQuantity =
                              timeItem.foods[0]?.groups
                                ?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                                ?.ingredents?.find(
                                  (ingredientObj) =>
                                    ingredientObj.id === ingredientItem.id
                                )?.ing_quantity || 0;

                            const groupQuantitative = Number(
                              Number(amountQuantity).toFixed(4) * mealQuantity
                            ).toFixed(4);

                            ingredientTotalQuantitative2 +=
                              parseFloat(groupQuantitative);
                          })}
                          <td>
                            <CurrencyFormat
                              value={
                                parseFloat(
                                  ingredientTotalQuantitative2 /
                                    timeItem.foods[0]?.groups?.reduce(
                                      (groupSum, group) =>
                                        groupSum + (group.totalRegister || 0),
                                      0
                                    )
                                ) || 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>

                          <td>
                            <CurrencyFormat
                              value={parseFloat(
                                ingredientTotalQuantitative2 /
                                  ingredientItem?.volume_cook || 0
                              )}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                        </tr>
                      );
                    })}

                {timeItem?.foods?.length > 0 &&
                  timeItem.foods
                    .slice(1, timeItem.foods.length)
                    ?.map((foodItem) => {
                      let ingredientTotalQuantitative3 = 0;

                      return (
                        <React.Fragment key={foodItem.id}>
                          <tr>
                            <td>empty</td>
                            <td>{foodItem.food_name}</td>
                            {quantitative?.costPerDays?.map((groupItem) => {
                              let mealQuantity = 0;
                              if (
                                foodItem?.groups?.some(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                              ) {
                                mealQuantity =
                                  foodItem?.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.totalRegister || 0;
                              }
                            })}
                            <td>
                              <CurrencyFormat
                                value={
                                  foodItem?.groups?.reduce(
                                    (groupSum, group) =>
                                      groupSum + (group.totalRegister || 0),
                                    0
                                  ) || 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              {foodItem.groups[0]?.ingredents[0]
                                ?.ingredent_name || "empty"}
                            </td>
                            <td>empty</td>
                            {quantitative?.costPerDays?.map((groupItem) => {
                              let mealQuantity = 0;

                              if (
                                foodItem?.groups?.some(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                              ) {
                                mealQuantity =
                                  foodItem?.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.totalRegister || 0;
                              }

                              const amountQuantity =
                                foodItem.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.ingredents[0]?.ing_quantity || 0;

                              const groupQuantitative = Number(
                                Number(amountQuantity).toFixed(4) * mealQuantity
                              ).toFixed(4);

                              ingredientTotalQuantitative3 +=
                                parseFloat(groupQuantitative);
                            })}
                            <td>
                              <CurrencyFormat
                                value={parseFloat(
                                  ingredientTotalQuantitative3 /
                                    foodItem?.groups?.reduce(
                                      (groupSum, group) =>
                                        groupSum + (group.totalRegister || 0),
                                      0
                                    ) ||
                                    0 ||
                                    0
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={1}
                              />
                            </td>

                            <td>
                              <CurrencyFormat
                                value={parseFloat(
                                  ingredientTotalQuantitative3 /
                                    foodItem.groups[0]?.ingredents[0]
                                      ?.volume_cook || 0
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={1}
                              />
                            </td>
                          </tr>

                          {foodItem.groups[0]?.ingredents
                            ?.slice(1, foodItem.groups[0]?.length)
                            ?.map((ingredientItem) => {
                              let ingredientTotalQuantitative4 = 0;

                              return (
                                <tr key={ingredientItem.id}>
                                  <td>empty</td>
                                  <td>empty</td>
                                  <td>empty</td>
                                  <td>
                                    {ingredientItem.ingredent_name || "empty"}
                                  </td>
                                  <td>empty</td>
                                  {quantitative?.costPerDays?.map(
                                    (groupItem) => {
                                      const mealQuantity =
                                        foodItem?.groups?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )?.totalRegister || 0;

                                      const amountQuantity =
                                        foodItem.groups
                                          ?.find(
                                            (groupObj) =>
                                              groupObj.id === groupItem.id_group
                                          )
                                          ?.ingredents?.find(
                                            (ingredientObj) =>
                                              ingredientObj.id ===
                                              ingredientItem.id
                                          )?.ing_quantity || 0;

                                      const groupQuantitative = Number(
                                        Number(amountQuantity).toFixed(4) *
                                          mealQuantity
                                      ).toFixed(4);

                                      ingredientTotalQuantitative4 +=
                                        parseFloat(groupQuantitative);
                                    }
                                  )}
                                  <td>
                                    <CurrencyFormat
                                      value={
                                        ingredientTotalQuantitative4 /
                                          foodItem?.groups?.reduce(
                                            (groupSum, group) =>
                                              groupSum +
                                              (group.totalRegister || 0),
                                            0
                                          ) || 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                    />
                                  </td>

                                  <td>
                                    <CurrencyFormat
                                      value={
                                        parseFloat(
                                          ingredientTotalQuantitative4 /
                                            ingredientItem?.volume_cook || 0
                                        ) || 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  };
  return (
    <>
      {data
        ?.filter((e) => Number(e.id_day) === Number(selectedDay))
        ?.map((item) => {
          return renderTable(item);
        })}
    </>
  );
};

export default ProductPlanExport;
