import React from "react";
import { Table, Modal, Row, Col, Button } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";
import Select from "react-select";

const QuantitativeKitchenTableExport = ({
  quantitative: data,
  show,
  onHide,
  classGroups,
  setSelectedClassGroups,
  selectedClassGroup,
  handleExport,
}) => {
  if (!data) {
    return "";
  }

  let quantitative = data;
  // if (selectedClassGroup) {
  const filteredCostPerDays = quantitative?.costPerDays;
  // ?.filter(
  //   (c) => Number(c.id_group) === Number(selectedClassGroup.id)
  // );

  const updatedTimes = quantitative?.times?.slice(1)?.map((time) => ({
    ...time,
    foods: time.foods
      ?.filter((fo) => Number(fo.is_main) === 1)
      ?.map((f) => ({
        ...f,
        groups: f.groups
          // ?.filter((g) => Number(g.id) === Number(selectedClassGroup.id))
          ?.map((g) => ({
            ...g,
            ingredents: g.ingredents?.filter((i) => Number(i.is_main) === 1),
          })),
      }))
      ?.filter((g) => g.groups?.length > 0),
  }));

  quantitative = {
    ...quantitative,
    costPerDays: filteredCostPerDays,
    times: updatedTimes,
  };
  // }

  const costPerDays = quantitative.costPerDays || [];
  const sumTotalRegisterByIdTime = (idTime) => {
    let times = quantitative.times;
    if (!Array.isArray(times)) {
      throw new Error("Lỗi");
    }

    return times
      .filter((time) => time.id_time === idTime)
      .reduce((sum, time) => {
        const foodRegisterSum = time.foods
          ?.filter((fo) => Number(fo.is_main) === 1)
          ?.reduce((foodSum, food) => {
            const groupRegisterSum = food.groups?.reduce(
              (groupSum, group) => groupSum + (group.totalRegister || 0),
              0
            );
            return foodSum + (groupRegisterSum || 0);
          }, 0);

        return sum + (foodRegisterSum || 0);
      }, 0);
  };
  return (
    <>
      <Table
        id={`quantitative-kitchen-table-by-group${selectedClassGroup?.id}`}
        className="d-none"
      >
        <thead>
          <tr>
            <th>
              TỔNG HỢP ĐỊNH LƯỢNG NGÀY{" "}
              {dateFormatter(new Date(quantitative.menu_date * 1000))}
            </th>

            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th> CA SX</th>
            <th>MÓN ĂN </th>
            <th>SỐ PHẦN ĂN</th>
            <th>NGUYÊN LIỆU CHÍNH</th>

            <th>ĐỊNH LƯỢNG (Gr)</th>

            <th> SL TP SỬ DỤNG (Kg)</th>
            <th>DT</th>
            <th>TỔNG SL P/ĂN ĐẶT</th>

            <th>SL PĂ TRƯỜNG ĐẶT</th>
            <th>SL PĂ DỰ TRÙ</th>
          </tr>
        </thead>
        <tbody>
          {quantitative.times?.map((timeItem) => {
            if (timeItem?.foods?.length === 0) {
              return (
                <tr key={timeItem.id}>
                  <td>{timeItem.time_name}</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>

                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            } else {
              let ingredientTotalQuantitative1 = 0;

              return (
                <React.Fragment key={timeItem.id}>
                  <tr>
                    <td>{timeItem.time_name}</td>
                    <td>
                      {timeItem?.foods?.length > 0
                        ? timeItem?.foods[0]?.food_name
                        : ""}
                    </td>

                    <td>
                      <CurrencyFormat
                        value={
                          timeItem?.foods?.length > 0
                            ? timeItem?.foods[0]?.groups?.reduce(
                                (groupSum, group) =>
                                  groupSum + (group.totalRegister || 0),
                                0
                              )
                            : 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      {timeItem?.foods?.length > 0
                        ? timeItem?.foods[0]?.groups[0]?.ingredents[0]
                            ?.ingredent_name
                        : "empty"}
                    </td>

                    {costPerDays?.map((groupItem) => {
                      if (timeItem.foods?.length > 0) {
                        const mealQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.totalRegister || 0;
                        const amountQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.ingredents[0]?.ing_quantity || 0;

                        const groupQuantitative = Number(
                          Number(amountQuantity).toFixed(4) * mealQuantity
                        ).toFixed(4);

                        ingredientTotalQuantitative1 +=
                          parseFloat(groupQuantitative);
                      } else ingredientTotalQuantitative1 = 0;
                    })}
                    <td>
                      <CurrencyFormat
                        value={
                          parseFloat(
                            ingredientTotalQuantitative1 /
                              (timeItem.foods?.length > 0
                                ? timeItem?.foods[0]?.groups?.reduce(
                                    (groupSum, group) =>
                                      groupSum + (group.totalRegister || 0),
                                    0
                                  )
                                : 1)
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>

                    <td>
                      <CurrencyFormat
                        value={
                          parseFloat(
                            ingredientTotalQuantitative1 /
                              (timeItem.foods?.length > 0
                                ? timeItem.foods[0]?.groups[0]?.ingredents[0]
                                    ?.volume_cook
                                : 1)
                            // timeItem.foods[0]?.groups[0]?.ingredents[0]
                            //   ?.volume_cook
                          ) || 0
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                      />
                    </td>
                    <td>empty</td>

                    <td>
                      <CurrencyFormat
                        value={sumTotalRegisterByIdTime(timeItem.id_time)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={sumTotalRegisterByIdTime(timeItem.id_time)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>empty</td>
                  </tr>

                  {timeItem?.foods?.length > 0 &&
                    timeItem?.foods[0]?.groups[0]?.ingredents
                      ?.slice(1, timeItem.foods[0]?.groups[0]?.length)
                      ?.map((ingredientItem) => {
                        let ingredientTotalQuantitative2 = 0;

                        return (
                          <tr key={ingredientItem.id}>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>{ingredientItem.ingredent_name || "empty"}</td>
                            {costPerDays?.map((groupItem) => {
                              const mealQuantity =
                                timeItem.foods[0]?.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.totalRegister || 0;

                              const amountQuantity =
                                timeItem.foods[0]?.groups
                                  ?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                  ?.ingredents?.find(
                                    (ingredientObj) =>
                                      ingredientObj.id === ingredientItem.id
                                  )?.ing_quantity || 0;

                              const groupQuantitative = Number(
                                Number(amountQuantity).toFixed(4) * mealQuantity
                              ).toFixed(4);

                              ingredientTotalQuantitative2 +=
                                parseFloat(groupQuantitative);
                            })}
                            <td>
                              <CurrencyFormat
                                value={
                                  parseFloat(
                                    ingredientTotalQuantitative2 /
                                      timeItem.foods[0]?.groups?.reduce(
                                        (groupSum, group) =>
                                          groupSum + (group.totalRegister || 0),
                                        0
                                      )
                                  ) || 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={1}
                              />
                            </td>

                            <td>
                              <CurrencyFormat
                                value={parseFloat(
                                  ingredientTotalQuantitative2 /
                                    ingredientItem?.volume_cook || 0
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={1}
                              />
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                          </tr>
                        );
                      })}

                  {timeItem?.foods?.length > 0 &&
                    timeItem.foods
                      ?.slice(1, timeItem.foods.length)
                      ?.map((foodItem) => {
                        let ingredientTotalQuantitative3 = 0;

                        return (
                          <React.Fragment key={foodItem.id}>
                            <tr>
                              <td>empty</td>
                              <td>{foodItem.food_name}</td>
                              {costPerDays?.map((groupItem) => {
                                let mealQuantity = 0;
                                if (
                                  foodItem?.groups?.some(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                ) {
                                  mealQuantity =
                                    foodItem?.groups?.find(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )?.totalRegister || 0;
                                }
                              })}
                              <td>
                                <CurrencyFormat
                                  value={
                                    foodItem?.groups?.reduce(
                                      (groupSum, group) =>
                                        groupSum + (group.totalRegister || 0),
                                      0
                                    ) || 0
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td>
                                {foodItem.groups[0]?.ingredents[0]
                                  ?.ingredent_name || "empty"}
                              </td>
                              {costPerDays?.map((groupItem) => {
                                const ingQuantity =
                                  foodItem.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.ingredents[0]?.ing_quantity || 0;
                              })}
                              {costPerDays?.map((groupItem) => {
                                let mealQuantity = 0;

                                if (
                                  foodItem?.groups?.some(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                ) {
                                  mealQuantity =
                                    foodItem?.groups?.find(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )?.totalRegister || 0;
                                }

                                const amountQuantity =
                                  foodItem.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.ingredents[0]?.ing_quantity || 0;

                                const groupQuantitative = Number(
                                  Number(amountQuantity).toFixed(4) *
                                    mealQuantity
                                ).toFixed(4);

                                ingredientTotalQuantitative3 +=
                                  parseFloat(groupQuantitative);
                              })}
                              <td>
                                <CurrencyFormat
                                  value={parseFloat(
                                    ingredientTotalQuantitative3 /
                                      foodItem?.groups?.reduce(
                                        (groupSum, group) =>
                                          groupSum + (group.totalRegister || 0),
                                        0
                                      ) ||
                                      0 ||
                                      0
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={1}
                                />
                              </td>

                              <td>
                                <CurrencyFormat
                                  value={parseFloat(
                                    ingredientTotalQuantitative3 /
                                      foodItem.groups[0]?.ingredents[0]
                                        ?.volume_cook || 0
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={1}
                                />
                              </td>
                              <td>empty</td>
                              <td>empty</td>
                              <td>empty</td>
                              <td>empty</td>
                            </tr>

                            {foodItem.groups[0]?.ingredents
                              ?.slice(1, foodItem.groups[0]?.length)
                              ?.map((ingredientItem) => {
                                let ingredientTotalQuantitative4 = 0;

                                return (
                                  <tr key={ingredientItem.id}>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>
                                      {ingredientItem.ingredent_name || "empty"}
                                    </td>
                                    {costPerDays?.map((groupItem) => {
                                      const ingQuantity =
                                        foodItem.groups
                                          ?.find(
                                            (groupObj) =>
                                              groupObj.id === groupItem.id_group
                                          )
                                          ?.ingredents?.find(
                                            (ingredientObj) =>
                                              ingredientObj.id ===
                                              ingredientItem.id
                                          )?.ing_quantity || 0;
                                    })}
                                    {costPerDays?.map((groupItem) => {
                                      const mealQuantity =
                                        foodItem?.groups?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )?.totalRegister || 0;

                                      const amountQuantity =
                                        foodItem.groups
                                          ?.find(
                                            (groupObj) =>
                                              groupObj.id === groupItem.id_group
                                          )
                                          ?.ingredents?.find(
                                            (ingredientObj) =>
                                              ingredientObj.id ===
                                              ingredientItem.id
                                          )?.ing_quantity || 0;

                                      const groupQuantitative = Number(
                                        Number(amountQuantity).toFixed(4) *
                                          mealQuantity
                                      ).toFixed(4);

                                      ingredientTotalQuantitative4 +=
                                        parseFloat(groupQuantitative);
                                    })}
                                    <td>
                                      <CurrencyFormat
                                        value={
                                          ingredientTotalQuantitative4 /
                                            foodItem?.groups?.reduce(
                                              (groupSum, group) =>
                                                groupSum +
                                                (group.totalRegister || 0),
                                              0
                                            ) || 0
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                      />
                                    </td>

                                    <td>
                                      <CurrencyFormat
                                        value={
                                          parseFloat(
                                            ingredientTotalQuantitative4 /
                                              ingredientItem?.volume_cook || 0
                                          ) || 0
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                      />
                                    </td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                  </tr>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                </React.Fragment>
              );
            }
          })}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>ĐẠI DIỆN NHÀ ĂN</th>
            <th></th>
            <th></th>
            <th>KẾ TOÁN</th>
            <th></th>
            <th></th>
            <th> ĐẠI DIỆN NHÀ TRƯỜNG</th>
            <th></th> <th></th> <th></th>
          </tr>
        </tfoot>
      </Table>
      {/* <Modal
        size=""
        backdrop="static"
        show={show}
        onHide={() => onHide()}
        // fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Xuất định lượng cho bếp theo nhóm</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-3">
            <Col xs={12} className="mb-3">
              <Select
                options={classGroups}
                placeholder={<div>Chọn nhóm</div>}
                getOptionLabel={(option) => option.group_name}
                getOptionValue={(option) => option.id}
                value={selectedClassGroup}
                onChange={(choice) => {
                  setSelectedClassGroups(choice);
                }}
              />
            </Col>
          </Row>
          <Table
            id={`quantitative-kitchen-table-by-group${selectedClassGroup?.id}`}
            className="d-none"
          >
            <thead>
              <tr>
                <th>
                  TỔNG HỢP ĐỊNH LƯỢNG NGÀY{" "}
                  {dateFormatter(new Date(quantitative.menu_date * 1000))}
                </th>

                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
                <th>empty</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th> CA SX</th>
                <th>MÓN ĂN </th>
                <th>SỐ PHẦN ĂN</th>
                <th>NGUYÊN LIỆU CHÍNH</th>

                <th>ĐỊNH LƯỢNG (Gr)</th>

                <th> SL TP SỬ DỤNG (Kg)</th>
                <th>DT</th>
                <th>TỔNG SL P/ĂN ĐẶT</th>

                <th>SL PĂ TRƯỜNG ĐẶT</th>
                <th>SL PĂ DỰ TRÙ</th>
              </tr>
            </thead>
            <tbody>
              {quantitative.times?.map((timeItem) => {
                if (timeItem.foods.length === 0) {
                  return (
                    <tr key={timeItem.id}>
                      <td>{timeItem.time_name}</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>

                      <td>empty</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                }

                let ingredientTotalQuantitative1 = 0;

                return (
                  <React.Fragment key={timeItem.id}>
                    <tr>
                      <td>{timeItem.time_name}</td>
                      <td>{timeItem.foods[0]?.food_name}</td>
                      {costPerDays?.map((groupItem) => {
                        let mealQuantity = 0;
                        if (
                          timeItem.foods[0]?.groups?.some(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )
                        ) {
                          mealQuantity =
                            timeItem.foods[0]?.groups?.find(
                              (groupObj) => groupObj.id === groupItem.id_group
                            )?.totalRegister || 0;
                        }

                        return (
                          <td key={groupItem.id_group}>
                            <CurrencyFormat
                              value={mealQuantity}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        );
                      })}
                      <td>
                        {timeItem.foods[0]?.groups[0]?.ingredents[0]
                          ?.ingredent_name || "empty"}
                      </td>
                      {costPerDays?.map((groupItem) => {
                        const ingQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.ingredents[0]?.ing_quantity || 0;

                        return (
                          <td key={groupItem.id_group}>
                            <CurrencyFormat
                              value={parseFloat(ingQuantity)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                        );
                      })}
                      {costPerDays?.map((groupItem) => {
                        const mealQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.totalRegister || 0;
                        const amountQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.ingredents[0]?.ing_quantity || 0;

                        const groupQuantitative = Number(
                          Number(amountQuantity).toFixed(4) * mealQuantity
                        ).toFixed(4);

                        ingredientTotalQuantitative1 +=
                          parseFloat(groupQuantitative);

                        // return (
                        //   <td key={groupItem.id_group}>
                        //     <CurrencyFormat
                        //       value={parseFloat(groupQuantitative)}
                        //       displayType={"text"}
                        //       thousandSeparator={true}
                        //       decimalScale={2}
                        //     />
                        //   </td>
                        // );
                      })}
                      <td>
                        <CurrencyFormat
                          value={parseFloat(ingredientTotalQuantitative1)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={1}
                        />
                      </td>
                      <td>empty</td>
                      {costPerDays?.map((groupItem) => {
                        let mealQuantity = 0;
                        if (
                          timeItem.foods[0]?.groups?.some(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )
                        ) {
                          mealQuantity =
                            timeItem.foods[0]?.groups?.find(
                              (groupObj) => groupObj.id === groupItem.id_group
                            )?.totalRegister || 0;
                        }

                        return (
                          <td key={groupItem.id_group}>
                            <CurrencyFormat
                              value={sumTotalRegisterByIdTime(timeItem.id_time)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        );
                      })}
                      <td>empty</td>
                      <td>empty</td>
                    </tr>

                    {timeItem.foods[0]?.groups[0]?.ingredents
                      ?.slice(1, timeItem.foods[0]?.groups[0]?.length)
                      ?.map((ingredientItem) => {
                        let ingredientTotalQuantitative2 = 0;

                        return (
                          <tr key={ingredientItem.id}>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>{ingredientItem.ingredent_name || "empty"}</td>
                            {costPerDays?.map((groupItem) => {
                              const ingQuantity =
                                timeItem.foods[0]?.groups
                                  ?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                  ?.ingredents?.find(
                                    (ingredientObj) =>
                                      ingredientObj.id === ingredientItem.id
                                  )?.ing_quantity || 0;

                              return (
                                <td key={groupItem.id_group}>
                                  <CurrencyFormat
                                    value={parseFloat(ingQuantity)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                  />
                                </td>
                              );
                            })}
                            {costPerDays?.map((groupItem) => {
                              const mealQuantity =
                                timeItem.foods[0]?.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.totalRegister || 0;

                              const amountQuantity =
                                timeItem.foods[0]?.groups
                                  ?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                  ?.ingredents?.find(
                                    (ingredientObj) =>
                                      ingredientObj.id === ingredientItem.id
                                  )?.ing_quantity || 0;

                              const groupQuantitative = Number(
                                Number(amountQuantity).toFixed(4) * mealQuantity
                              ).toFixed(4);

                              ingredientTotalQuantitative2 +=
                                parseFloat(groupQuantitative);

                              // return (
                              //   <td key={groupItem.id_group}>
                              //     <CurrencyFormat
                              //       value={parseFloat(groupQuantitative)}
                              //       displayType={"text"}
                              //       thousandSeparator={true}
                              //       decimalScale={2}
                              //     />
                              //   </td>
                              // );
                            })}
                            <td>
                              <CurrencyFormat
                                value={parseFloat(ingredientTotalQuantitative2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={1}
                              />
                            </td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                            <td>empty</td>
                          </tr>
                        );
                      })}

                    {timeItem.foods
                      .slice(1, timeItem.foods.length)
                      ?.map((foodItem) => {
                        let ingredientTotalQuantitative3 = 0;

                        return (
                          <React.Fragment key={foodItem.id}>
                            <tr>
                              <td>empty</td>
                              <td>{foodItem.food_name}</td>
                              {costPerDays?.map((groupItem) => {
                                let mealQuantity = 0;
                                if (
                                  foodItem?.groups?.some(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                ) {
                                  mealQuantity =
                                    foodItem?.groups?.find(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )?.totalRegister || 0;
                                }

                                return (
                                  <td key={groupItem.id_group}>
                                    <CurrencyFormat
                                      value={mealQuantity}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </td>
                                );
                              })}
                              <td>
                                {foodItem.groups[0]?.ingredents[0]
                                  ?.ingredent_name || "empty"}
                              </td>
                              {costPerDays?.map((groupItem) => {
                                const ingQuantity =
                                  foodItem.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.ingredents[0]?.ing_quantity || 0;

                                return (
                                  <td key={groupItem.id_group}>
                                    <CurrencyFormat
                                      value={parseFloat(ingQuantity)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={1}
                                    />
                                  </td>
                                );
                              })}
                              {costPerDays?.map((groupItem) => {
                                let mealQuantity = 0;

                                if (
                                  foodItem?.groups?.some(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )
                                ) {
                                  mealQuantity =
                                    foodItem?.groups?.find(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )?.totalRegister || 0;
                                }

                                const amountQuantity =
                                  foodItem.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.ingredents[0]?.ing_quantity || 0;

                                const groupQuantitative = Number(
                                  Number(amountQuantity).toFixed(4) *
                                    mealQuantity
                                ).toFixed(4);

                                ingredientTotalQuantitative3 +=
                                  parseFloat(groupQuantitative);

                                // return (
                                //   <td key={groupItem.id_group}>
                                //     <CurrencyFormat
                                //       value={parseFloat(groupQuantitative)}
                                //       displayType={"text"}
                                //       thousandSeparator={true}
                                //       decimalScale={2}
                                //     />
                                //   </td>
                                // );
                              })}
                              <td>
                                <CurrencyFormat
                                  value={parseFloat(
                                    ingredientTotalQuantitative3
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={1}
                                />
                              </td>
                              <td>empty</td>
                              <td>empty</td>
                              <td>empty</td>
                              <td>empty</td>
                            </tr>

                            {foodItem.groups[0]?.ingredents
                              ?.slice(1, foodItem.groups[0]?.length)
                              ?.map((ingredientItem) => {
                                let ingredientTotalQuantitative4 = 0;

                                return (
                                  <tr key={ingredientItem.id}>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>
                                      {ingredientItem.ingredent_name || "empty"}
                                    </td>
                                    {costPerDays?.map((groupItem) => {
                                      const ingQuantity =
                                        foodItem.groups
                                          ?.find(
                                            (groupObj) =>
                                              groupObj.id === groupItem.id_group
                                          )
                                          ?.ingredents?.find(
                                            (ingredientObj) =>
                                              ingredientObj.id ===
                                              ingredientItem.id
                                          )?.ing_quantity || 0;
                                      return (
                                        <td key={groupItem.id_group}>
                                          <CurrencyFormat
                                            value={parseFloat(ingQuantity)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            decimalScale={1}
                                          />
                                        </td>
                                      );
                                    })}
                                    {costPerDays?.map((groupItem) => {
                                      const mealQuantity =
                                        foodItem?.groups?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )?.totalRegister || 0;

                                      const amountQuantity =
                                        foodItem.groups
                                          ?.find(
                                            (groupObj) =>
                                              groupObj.id === groupItem.id_group
                                          )
                                          ?.ingredents?.find(
                                            (ingredientObj) =>
                                              ingredientObj.id ===
                                              ingredientItem.id
                                          )?.ing_quantity || 0;

                                      const groupQuantitative = Number(
                                        Number(amountQuantity).toFixed(4) *
                                          mealQuantity
                                      ).toFixed(4);

                                      ingredientTotalQuantitative4 +=
                                        parseFloat(groupQuantitative);

                                      // return (
                                      //   <td key={groupItem.id_group}>
                                      //     <CurrencyFormat
                                      //       value={parseFloat(
                                      //         groupQuantitative
                                      //       )}
                                      //       displayType={"text"}
                                      //       thousandSeparator={true}
                                      //       decimalScale={2}
                                      //     />
                                      //   </td>
                                      // );
                                    })}
                                    <td>
                                      <CurrencyFormat
                                        value={parseFloat(
                                          ingredientTotalQuantitative4
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={1}
                                      />
                                    </td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                    <td>empty</td>
                                  </tr>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}
                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}
                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}
              </tr>
              <tr>
                <th>ĐẠI DIỆN NHÀ ĂN</th>
                <th></th>
                <th></th>
                <th>KẾ TOÁN</th>
                <th></th>
                <th></th>
                <th> ĐẠI DIỆN NHÀ TRƯỜNG</th>
                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}

                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}
                {costPerDays?.map((groupItem) => {
                  return <th key={groupItem.id_group}></th>;
                })}
              </tr>
            </tfoot>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>

          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => {
              handleExport();
            }}
          >
            <i className="fa-solid fa-check"></i> Xuất
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default QuantitativeKitchenTableExport;
