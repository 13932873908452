import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";

const SummaryTable = ({
  quantitative,
  setQuantitative,
  selectedClassGroup,
  ingredients,
  registerMeals,
  settingsMealRegistration,
  expenditureNorms,
  mealsPrice,
  setMealsPrice,
}) => {
  useEffect(() => {});
  const times = registerMeals?.times || [];

  const timeExpenditureNormsByGroup =
    expenditureNorms.length > 0
      ? expenditureNorms?.find(
          (group) => group.id_group == selectedClassGroup.id
        )?.times
      : [];

  const countPortionsWithConditions = (data, payload) => {
    const { timesId, categoriesId } = payload;

    const result = data?.times?.map((time) => {
      const validFoods = timesId.includes(time.id)
        ? time.foods.filter((food) => categoriesId.includes(food.id_category)) // Lọc theo `categoriesId`
        : time.foods;

      const totalPortions = validFoods.reduce((sum, food) => {
        return (
          sum +
          food.groups
            ?.filter((group) => group.id === selectedClassGroup.id)
            ?.reduce(
              (groupSum, group) => groupSum + group.number_of_portions,
              0
            )
        );
      }, 0);

      return {
        id_time: time.id,
        time_name: time.time,
        total_portions: totalPortions,
      };
    });

    return result;
  };

  const mealsFeeByGroup = mealsPrice?.groups?.find(
    (g) => g.id_group === selectedClassGroup.id
  );

  const registerMealByTime = selectedClassGroup
    ? countPortionsWithConditions(registerMeals, settingsMealRegistration)
    : [];

  const costInformation = times?.map((time) => {
    const expenditureNormTime = timeExpenditureNormsByGroup.find(
      (time2) => time2.id_time === time.id
    );

    const registerMeal = registerMealByTime?.find(
      (registerTime, indexRegisterTime) => registerTime.id_time === time.id
    );

    const total_portions = registerMeal.total_portions;
    const meal_price = expenditureNormTime?.meal_price;

    const cashReceived = total_portions * meal_price;

    const foodsByTime = quantitative?.times?.find(
      (foodTime) => foodTime.id_time === time.id
    )?.foods;

    let totalCostOfIngredients = 0;

    foodsByTime?.map((food) => {
      const ingredients = food.groups.find(
        (group) => group.id === selectedClassGroup.id
      )?.ingredents;

      const number_of_portions = registerMeals?.times
        ?.find((timeItem) => timeItem.id === time.id)
        ?.foods?.find((food2) => food2.id === food.id)
        ?.groups?.find(
          (group) => group.id === selectedClassGroup.id
        )?.number_of_portions;

      let priceFood = 0;

      ingredients?.map((ingredient) => {
        const ingredientPrice = Number(ingredient.ing_price_expect).toFixed(0);
        const ingredientQuantity =
          Number(ingredient.ing_quantity).toFixed(1) / ingredient.volume_cook;

        priceFood += parseFloat(
          Number(ingredientPrice * ingredientQuantity).toFixed(0)
        );
      });

      totalCostOfIngredients +=
        Number(priceFood).toFixed(0) * number_of_portions || 0;
    });

    let sumFeeByTime = 0;

    mealsFeeByGroup?.items
      ?.filter((item) => item.item_name !== "")
      ?.map((item) => {
        const feeByTime = item.times.find(
          (timeFee) => timeFee.id_time === time.id
        );

        sumFeeByTime += feeByTime.meal_price * total_portions;
      });

    const totalFeeByTime = totalCostOfIngredients + sumFeeByTime;

    return {
      id_time: time.id,
      time_name: time.time,
      total_portions,
      meal_price,
      cashReceived,
      totalCostOfIngredients,
      totalFeeByTime,
      totalEndDayMoney: cashReceived - totalFeeByTime,
    };
  });

  return (
    <Table bordered responsive>
      <thead className="align-middle text-center bg-light">
        <tr>
          <th style={{ width: "10rem" }}>Diễn giải</th>
          {times?.map((time, indexTime) => {
            return <th>{time.time}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Số lượng đăng ký</th>
          {costInformation?.map((costs, indexCosts) => {
            return <td className="text-end">{costs.total_portions}</td>;
          })}
        </tr>
        <tr>
          <th>Tiền định mức</th>
          {costInformation?.map((costs, indexCosts) => {
            return (
              <td className="text-end">
                <CurrencyFormat
                  value={costs.meal_price}
                  thousandSeparator={true}
                  displayType="text"
                  allowNegative={false}
                  decimalScale={0}
                />
              </td>
            );
          })}
        </tr>

        <tr>
          <th>Tiền thu trong ngày</th>
          {costInformation?.map((costs, indexCosts) => {
            return (
              <td className="text-end text-success">
                <CurrencyFormat
                  value={costs.cashReceived}
                  thousandSeparator={true}
                  displayType="text"
                  allowNegative={false}
                  decimalScale={0}
                />
              </td>
            );
          })}
        </tr>

        <tr>
          <th>Tiền chi thực phẩm</th>
          {costInformation?.map((costs, indexCosts) => {
            return (
              <td className="text-end">
                <CurrencyFormat
                  value={costs.totalCostOfIngredients}
                  thousandSeparator={true}
                  displayType="text"
                  allowNegative={false}
                  decimalScale={0}
                />
              </td>
            );
          })}
        </tr>

        {mealsFeeByGroup?.items
          ?.filter((item) => item.item_name !== "")
          ?.map((item) => {
            return (
              <tr>
                <th>{item.item_name}</th>
                {costInformation?.map((time, indexTime) => {
                  const mealPriceTime = item.times.find(
                    (time2) => time2.id_time === time.id_time
                  );

                  return (
                    <td className="text-end">
                      <CurrencyFormat
                        value={mealPriceTime?.meal_price}
                        thousandSeparator={true}
                        displayType="input"
                        allowNegative={false}
                        decimalScale={0}
                        style={{ width: "80px" }}
                        className="form-control text-end"
                        onValueChange={(values) => {
                          setMealsPrice({
                            ...mealsPrice,
                            groups: mealsPrice?.groups?.map((group) => {
                              return group.id_group === selectedClassGroup.id // Điều kiện `id_group`
                                ? {
                                    ...group,
                                    items: group.items.map((item2) => {
                                      return item2.id_item === item.id_item // Điều kiện `id_item`
                                        ? {
                                            ...item2,
                                            times: item2.times.map((time2) =>
                                              time2.id_time === time.id_time // Điều kiện `id_time`
                                                ? {
                                                    ...time2,
                                                    meal_price: values.value,
                                                  } // Cập nhật `meal_price`
                                                : time2
                                            ),
                                          }
                                        : item2;
                                    }),
                                  }
                                : group;
                            }),
                          });
                        }}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}

        <tr>
          <th>Tiền chi trong ngày</th>
          {costInformation?.map((costs, indexCosts) => {
            return (
              <td className="text-end text-danger">
                <CurrencyFormat
                  value={costs.totalFeeByTime}
                  thousandSeparator={true}
                  displayType="text"
                  allowNegative={false}
                  decimalScale={0}
                />
              </td>
            );
          })}
        </tr>

        <tr>
          <th>Số dư cuối ngày</th>
          {costInformation?.map((costs, indexCosts) => {
            return (
              <td className="text-end fw-bold">
                <CurrencyFormat
                  value={costs.totalEndDayMoney}
                  thousandSeparator={true}
                  displayType="text"
                  allowNegative={false}
                  decimalScale={0}
                />
              </td>
            );
          })}
        </tr>

        {/* {settingsMealRegistration?.items?.map((item, indexItem) => {
          return (
            <tr key={`item-key-${indexItem}`}>
              <td className="text-center">{indexItem + 1}</td>
            </tr>
          );
        })} */}
      </tbody>
    </Table>
  );
};

export default SummaryTable;
