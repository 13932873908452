import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
const useBlocker = (shouldBlock, message) => {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!shouldBlock) return;
    const originalPush = navigator.push;
    navigator.push = (...args) => {
      if (!window.confirm(message)) {
        return;
      }
      originalPush(...args);
    };
    return () => {
      navigator.push = originalPush;
    };
  }, [shouldBlock, message, navigator]);
};
export const UnsavedChangesHandler = ({ hasChanges }) => {
  useBlocker(
    hasChanges,
    "Bạn có thay đổi chưa được lưu. Nếu rời đi, mọi thay đổi sẽ bị mất. Bạn có chắc chắn muốn rời đi?"
  );
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);
  return null;
};
